import { Menu, Button, Dropdown } from 'antd';
import React from 'react';


export default function ThemeChanger() {
	return (
		<div style={{ position: 'fixed', right: 20, bottom: 50 }}>
			<Dropdown overlay={<Menu
				onClick={(e) => {
					if (e.key === 'dark') {
						window.localStorage.setItem('theme', 'dark');
						// document.getElementById('--antd-theme--').setAttribute('href', '/css/antd.dark.min.css');
					} else if (e.key === 'compact') {
						window.localStorage.setItem('theme', 'compact');
						// document.getElementById('--antd-theme--').setAttribute('href', '/css/antd.compact.min.css');
					} else {
						window.localStorage.setItem('theme', 'light');
						// document.getElementById('--antd-theme--').setAttribute('href', '/css/antd.min.css');
					}
					window.location.reload();
				}}
				items={[{ key: 'light', label: 'Light' }, { key: 'dark', label: 'Dark' }, { key: 'compact', label: 'Compact' }
				]} />}
				placement="top"
			>
				<Button>Theme</Button>
			</Dropdown>
		</div>
	);
};